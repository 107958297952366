import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { wei } from '@synthetixio/wei'
import {
	AssetDisplayByAsset,
	MarketKeyByAsset,
	getDisplayAsset,
	formatDollars,
	formatNumber,
} from '@dextoroprotocol/sdk/utils'
import { FuturesMarket, FuturesMarketAsset } from '@dextoroprotocol/sdk/types'

import {
	selectDailyTrades,
	selectMarkets,
	selectMarketVolumes,
	selectMarkPrices,
} from 'state/futures/selectors'
import { useAppSelector } from 'state/hooks'
import { selectFuturesType } from 'state/futures/common/selectors'
import { selectPreviousDayPrices, selectOffchainPricesInfo } from 'state/prices/selectors'

import Spacer from 'components/Spacer'
import { Body } from 'components/Text'
import Currency from 'components/Currency'
import MarketBadge from 'components/MarketBadge'
import ChangePercent from 'components/ChangePercent'
import {
	DesktopLargeOnlyView,
	DesktopOnlyView,
	DesktopSmallOnlyView,
	MobileOrTabletView,
} from 'components/Media'
import { FlexDivCol } from 'components/layout/flex'
import SearchBarNewMarket from 'components/SearchBar/SearchBarNewMarket'
import { BaseTableBodyRow, TableCell } from 'components/Table/TableBodyRow'
import Table, { TableCellHead, TableHeader, TableNoResults } from 'components/Table'
import SearchIcon from 'assets/svg/app/search.svg'

import ROUTES from 'constants/routes'
import { weiSortingFn } from 'utils/table'
import { getSynthDescription } from 'utils/futures'
import { convertMarketName } from 'utils/formatters/marketName'
import media from 'styles/media'

type FuturesMarketsTableProps = {
	search?: string
	activeFilter?: string
	setActiveFilter?: (e: string) => void
	setSearch?: (e: string) => void
}

const sortBy = [{ id: 'dailyVolume', desc: true }]

const FuturesMarketsTable: React.FC<FuturesMarketsTableProps> = ({
	search,
	activeFilter,
	setActiveFilter,
	setSearch,
}) => {
	const router = useRouter()
	const { t } = useTranslation()

	const futuresMarkets = useAppSelector(selectMarkets)
	const pastRates = useAppSelector(selectPreviousDayPrices)
	const futuresVolumes = useAppSelector(selectMarketVolumes)
	const accountType = useAppSelector(selectFuturesType)
	const pricesInfo = useAppSelector(selectOffchainPricesInfo)
	const markPrices = useAppSelector(selectMarkPrices)
	const dailyTrades = useAppSelector(selectDailyTrades)

	const [totaltradingVolume, setTotalTradingVolume] = useState(0)
	const [totalopenInterest, setTotalsetOpenInterest] = useState(0)
	const [totalTradesCount, setTotalTradesCount] = useState(0)
	const [searchOpen, setSearchOpen] = useState(false)

	const onClearSearch = useCallback(() => {
		setSearchOpen(false)
		setSearch!('')
	}, [setSearch])

	let data = useMemo(() => {
		let tradingVolume = 0
		let openInterest = 0
		let tradeCount = 0
		const lowerSearch = search?.toLowerCase()
		const markets: FuturesMarket[] = lowerSearch
			? (futuresMarkets as FuturesMarket[]).filter(
					(m) =>
						m.asset.toLowerCase().includes(lowerSearch) ||
						AssetDisplayByAsset[m.asset]?.toLocaleLowerCase().includes(lowerSearch)
			  )
			: futuresMarkets
		if (activeFilter === 'forex') {
			let filterOutArray = ['XAU', 'XAG', 'EUR', 'GBP', 'AUD']
			const getForexMarket = markets.filter((m) => filterOutArray.includes(m.asset))

			const filteredData = getForexMarket.map((market) => {
				const description = getSynthDescription(market.asset, t)
				const volume = futuresVolumes[market.marketKey]?.volume
				const assetPriceInfo = pricesInfo[market.asset]
				const pastPrice = pastRates.find(
					(price) => price.synth === getDisplayAsset(market.asset)?.toUpperCase()
				)
				const marketPrice = markPrices[market.marketKey] ?? wei(0)
				tradingVolume += volume?.toNumber() ?? 0
				openInterest += market.marketSize.mul(marketPrice).toNumber()
				tradeCount += dailyTrades.filter((trade) => trade.asset === market.assetHex).length

				return {
					asset: market.asset,
					market: convertMarketName(market.marketName),
					description,
					price: marketPrice,
					priceInfo: assetPriceInfo,
					volume: volume?.toNumber() ?? 0,
					pastPrice: pastPrice?.rate,
					priceChange:
						pastPrice?.rate && marketPrice.gt(0)
							? marketPrice.sub(pastPrice?.rate).div(marketPrice)
							: wei(0),
					fundingRate: market.currentFundingRate ?? null,
					openInterest: market.marketSize.mul(marketPrice),
					openInterestNative: market.marketSize,
					longInterest: market.openInterest.longUSD,
					shortInterest: market.openInterest.shortUSD,
					marketSkew: market.marketSkew,
					isSuspended: market.isSuspended,
					marketClosureReason: market.marketClosureReason,
				}
			})
			setTotalTradingVolume(tradingVolume)
			setTotalsetOpenInterest(openInterest)
			setTotalTradesCount(tradeCount)
			return filteredData
		}

		if (activeFilter === 'defi') {
			let filterOutArray = [
				'YFI',
				'MKR',
				'AAVE',
				'COMP',
				'GMX',
				'INJ',
				'LINK',
				'RPL',
				'FXS',
				'UNI',
				'BAL',
				'DYDX',
				'LDO',
				'UMA',
				'SUSHI',
				'KNC',
				'PERP',
				'CRV',
				'1INCH',
				'PYTH',
				'TRB',
				'GRT',
				'JTO',
				'JUP',
				'CVX',
			]
			const getForexMarket = markets.filter((m) => filterOutArray.includes(m.asset))

			const filteredData = getForexMarket.map((market) => {
				const description = getSynthDescription(market.asset, t)
				const volume = futuresVolumes[market.marketKey]?.volume
				const assetPriceInfo = pricesInfo[market.asset]
				const pastPrice = pastRates.find(
					(price) => price.synth === getDisplayAsset(market.asset)?.toUpperCase()
				)
				const marketPrice = markPrices[market.marketKey] ?? wei(0)
				tradingVolume += volume?.toNumber() ?? 0
				openInterest += market.marketSize.mul(marketPrice).toNumber()
				tradeCount += dailyTrades.filter((trade) => trade.asset === market.assetHex).length

				return {
					asset: market.asset,
					market: convertMarketName(market.marketName),
					description,
					price: marketPrice,
					priceInfo: assetPriceInfo,
					volume: volume?.toNumber() ?? 0,
					pastPrice: pastPrice?.rate,
					priceChange:
						pastPrice?.rate && marketPrice.gt(0)
							? marketPrice.sub(pastPrice?.rate).div(marketPrice)
							: wei(0),
					fundingRate: market.currentFundingRate ?? null,
					openInterest: market.marketSize.mul(marketPrice),
					openInterestNative: market.marketSize,
					longInterest: market.openInterest.longUSD,
					shortInterest: market.openInterest.shortUSD,
					marketSkew: market.marketSkew,
					isSuspended: market.isSuspended,
					marketClosureReason: market.marketClosureReason,
				}
			})
			setTotalTradingVolume(tradingVolume)
			setTotalsetOpenInterest(openInterest)
			setTotalTradesCount(tradeCount)
			return filteredData
		}

		const filteredData = markets.map((market) => {
			const description = getSynthDescription(market.asset, t)
			const volume = futuresVolumes[market.marketKey]?.volume
			const assetPriceInfo = pricesInfo[market.asset]
			const pastPrice = pastRates.find(
				(price) => price.synth === getDisplayAsset(market.asset)?.toUpperCase()
			)
			const marketPrice = markPrices[market.marketKey] ?? wei(0)
			tradingVolume += volume?.toNumber() ?? 0
			openInterest += market.marketSize.mul(marketPrice).toNumber()
			tradeCount += dailyTrades?.filter((trade) => trade.asset === market.assetHex).length

			return {
				asset: market.asset,
				market: convertMarketName(market.marketName),
				description,
				price: marketPrice,
				priceInfo: assetPriceInfo,
				volume: volume?.toNumber() ?? 0,
				pastPrice: pastPrice?.rate,
				priceChange:
					pastPrice?.rate && marketPrice.gt(0)
						? marketPrice.sub(pastPrice?.rate).div(marketPrice)
						: wei(0),
				fundingRate: market.currentFundingRate ?? null,
				openInterest: market.marketSize.mul(marketPrice),
				openInterestNative: market.marketSize,
				longInterest: market.openInterest.longUSD,
				shortInterest: market.openInterest.shortUSD,
				marketSkew: market.marketSkew,
				isSuspended: market.isSuspended,
				marketClosureReason: market.marketClosureReason,
			}
		})
		setTotalTradingVolume(tradingVolume)
		setTotalsetOpenInterest(openInterest)
		setTotalTradesCount(tradeCount)
		return filteredData
	}, [
		search,
		futuresMarkets,
		t,
		futuresVolumes,
		pricesInfo,
		pastRates,
		markPrices,
		activeFilter,
		dailyTrades,
	])

	return (
		<>
			<DesktopLargeOnlyView>
				<HeroContainer>
					<FlexDivCol rowGap="16px">
						<Body fontSize={14} color="secondary">
							Trading Volume
						</Body>
						<FlexDivCol rowGap="4px">
							<Body fontSize={28}>{formatDollars(totaltradingVolume, { minDecimals: 0 })}</Body>
							<Body fontSize={14} color="secondary">
								exchanged in the last 24h
							</Body>
						</FlexDivCol>
					</FlexDivCol>
					<Divider />
					<FlexDivCol rowGap="16px">
						<Body fontSize={14} color="secondary">
							Open Interest
						</Body>
						<FlexDivCol rowGap="4px">
							<Body fontSize={28}>{formatDollars(totalopenInterest, { minDecimals: 0 })}</Body>
							<Body fontSize={14} color="secondary">
								in open positions on DexToro
							</Body>
						</FlexDivCol>
					</FlexDivCol>
					<Divider />
					<FlexDivCol rowGap="16px">
						<Body fontSize={14} color="secondary">
							Trades
						</Body>
						<FlexDivCol rowGap="4px">
							<Body fontSize={28}>{formatNumber(totalTradesCount, { minDecimals: 0 })}</Body>
							<Body fontSize={14} color="secondary">
								exchanged in the last 24h
							</Body>
						</FlexDivCol>
					</FlexDivCol>
				</HeroContainer>
				<TableContainer>
					<StyledTable
						data={data}
						showPagination
						onTableRowClick={(row) => {
							router.push(ROUTES.Markets.MarketPair(row.original.asset, accountType))
						}}
						highlightRowsOnHover
						sortBy={sortBy}
						columns={[
							{
								header: () => (
									<TableHeader style={{ textAlign: 'start' }}>
										{t('dashboard.overview.futures-markets-table.market')}
									</TableHeader>
								),
								accessorKey: 'market',
								cell: (cellProps) => {
									return (
										<MarketContainer>
											{/* <StyledCurrencyIcon
												currencyKey={MarketKeyByAsset[cellProps.row.original.asset]}
											/> */}
											<MarketContent>
												<StyledText>
													{cellProps.row.original.description}
													<Spacer width={8} />
													<MarketBadge
														currencyKey={cellProps.row.original.asset}
														isFuturesMarketClosed={cellProps.row.original.isSuspended}
														futuresClosureReason={cellProps.row.original.marketClosureReason}
													/>
												</StyledText>
												<StyledValue>{cellProps.row.original.market}</StyledValue>
											</MarketContent>
										</MarketContainer>
									)
								},
								size: 120,
								enableSorting: true,
							},
							{
								header: () => (
									<TableHeader>
										{t('dashboard.overview.futures-markets-table.mark-price')}
									</TableHeader>
								),
								accessorKey: 'price',
								cell: (cellProps) => {
									return <Currency.Price price={cellProps.row.original.price} fontSize={12} mono />
								},
								size: 100,
								enableSorting: true,
								sortingFn: weiSortingFn('price'),
							},
							{
								header: () => (
									<TableHeader>
										{t('dashboard.overview.futures-markets-table.daily-change')}
									</TableHeader>
								),
								accessorKey: 'priceChange',
								cell: (cellProps) => {
									return (
										<ChangePercent
											value={cellProps.row.original.priceChange}
											decimals={2}
											className="change-pct"
											mono
										/>
									)
								},
								size: 100,
								enableSorting: true,
								sortingFn: weiSortingFn('priceChange'),
							},
							{
								header: () => (
									<TableHeader>
										{t('dashboard.overview.futures-markets-table.funding-rate')}
									</TableHeader>
								),
								accessorKey: 'fundingRate',
								cell: (cellProps) => {
									return (
										<ChangePercent
											value={cellProps.row.original.fundingRate}
											decimals={6}
											showArrow={false}
											showMinus={true}
											className="change-pct"
											mono
										/>
									)
								},
								enableSorting: true,
								size: 100,
								sortingFn: weiSortingFn('fundingRate'),
							},
							{
								header: () => (
									<TableHeader>
										{t('dashboard.overview.futures-markets-table.open-interest')}
									</TableHeader>
								),
								accessorKey: 'openInterest',
								cell: (cellProps) => {
									return (
										<OpenInterestContainer>
											<Currency.Price
												price={cellProps.row.original.longInterest}
												colorType="positive"
												// formatOptions={{ truncateOver: 1e3 }}
												fontSize={12}
												mono
											/>
											<Currency.Price
												price={cellProps.row.original.shortInterest}
												colorType="negative"
												// formatOptions={{ truncateOver: 1e3 }}
												fontSize={12}
												mono
											/>
										</OpenInterestContainer>
									)
								},
								size: 100,
								enableSorting: true,
								sortingFn: weiSortingFn('openInterest'),
							},
							{
								id: 'dailyVolume',
								header: () => (
									<TableHeader>
										{t('dashboard.overview.futures-markets-table.daily-volume')}
									</TableHeader>
								),
								accessorKey: 'dailyVolume',
								cell: (cellProps) => {
									return (
										<Currency.Price
											price={cellProps.row.original.volume}
											// formatOptions={{ truncateOver: 1e3 }}
											mono
										/>
									)
								},
								size: 50,
								enableSorting: true,
								sortingFn: weiSortingFn('volume'),
							},
						]}
						noResultsMessage={
							data?.length === 0 &&
							(activeFilter === 'forex' ? (
								<TableNoResults>
									{t('dashboard.overview.futures-markets-table.no-forex-pairs')}
								</TableNoResults>
							) : (
								<TableNoResults>
									{t('dashboard.overview.futures-markets-table.no-results')}
								</TableNoResults>
							))
						}
					/>
				</TableContainer>
			</DesktopLargeOnlyView>
			<DesktopSmallOnlyView>
				<HeroContainer>
					<FlexDivCol rowGap="10px">
						<Body fontSize={14} color="secondary">
							24h Volume
						</Body>
						<Currency.Price
							price={totaltradingVolume ?? 0}
							formatOptions={{ truncateOver: 1e3 }}
							fontSize={24}
							colorType="primary"
						/>
					</FlexDivCol>
					<Divider />
					<FlexDivCol rowGap="10px">
						<Body fontSize={14} color="secondary">
							Open Interest
						</Body>
						<Currency.Price
							price={totalopenInterest ?? 0}
							formatOptions={{ truncateOver: 1e3 }}
							fontSize={24}
							colorType="primary"
						/>
					</FlexDivCol>
					<Divider />
					<FlexDivCol rowGap="10px">
						<Body fontSize={14} color="secondary">
							Trades
						</Body>
						<Body fontSize={28}>
							{formatNumber(totalTradesCount, { truncateOver: 1e3, minDecimals: 0 })}
						</Body>
					</FlexDivCol>
				</HeroContainer>
				<ButtonMobileDiv>
					{!searchOpen ? (
						<>
							<TabButtonMobileDiv>
								<ButtonFilter
									active={activeFilter === 'all' ? true : false}
									onClick={() => setActiveFilter!('all')}
								>
									All
								</ButtonFilter>
								<ButtonFilter
									active={activeFilter === 'forex' ? true : false}
									onClick={() => setActiveFilter!('forex')}
								>
									Forex
								</ButtonFilter>
								<ButtonFilter
									active={activeFilter === 'defi' ? true : false}
									onClick={() => setActiveFilter!('defi')}
								>
									DeFi
								</ButtonFilter>
							</TabButtonMobileDiv>
							<SearchIconStyle onClick={() => setSearchOpen(true)}>
								<SearchIcon height={22} width={22}></SearchIcon>
							</SearchIconStyle>
						</>
					) : (
						<SearchBarContainer searchOpen={searchOpen}>
							<SearchBarNewMarket
								autoFocus
								onChange={setSearch!}
								value={search}
								border={false}
								placeHolder={'Search markets'}
								onClear={onClearSearch}
								mobile={true}
							/>
						</SearchBarContainer>
					)}
				</ButtonMobileDiv>
				<StyledMobileTable
					data={data}
					showPagination
					rounded={false}
					onTableRowClick={(row) => {
						router.push(ROUTES.Markets.MarketPair(row.original.asset, accountType))
					}}
					sortBy={sortBy}
					columns={[
						{
							header: () => (
								<TableHeader>{t('dashboard.overview.futures-markets-table.market')}</TableHeader>
							),
							accessorKey: 'market',
							cell: (cellProps) => {
								return (
									<MarketContainer>
										{/* <StyledCurrencyIcon
											currencyKey={
												MarketKeyByAsset[cellProps.row.original.asset as FuturesMarketAsset]
											}
										/> */}
										<MarketContent>
											<StyledText mobile>
												{cellProps.row.original.description}
												<Spacer width={4} />
												<MarketBadge
													currencyKey={cellProps.row.original.asset}
													isFuturesMarketClosed={cellProps.row.original.isSuspended}
													futuresClosureReason={cellProps.row.original.marketClosureReason}
												/>
											</StyledText>
											<StyledValue mobile>{cellProps.row.original.market}</StyledValue>
										</MarketContent>
									</MarketContainer>
								)
							},
							size: 120,
							enableSorting: true,
						},
						{
							header: () => (
								<FlexDivCol alignItems="flex-end">
									<TableHeader>
										{t('dashboard.overview.futures-markets-table.mark-price')}
									</TableHeader>
									<TableHeader>
										{t('dashboard.overview.futures-markets-table.daily-change')}
									</TableHeader>
								</FlexDivCol>
							),
							accessorKey: 'price',
							cell: (cellProps) => {
								return (
									<FlexDivRight>
										<Currency.Price price={cellProps.row.original.price} fontSize={14} mono />
										<ChangePercent
											value={cellProps.row.original.priceChange ?? 0}
											decimals={2}
											className="change-pct"
											fontSize={14}
											mono
										/>
									</FlexDivRight>
								)
							},
							size: 100,
							enableSorting: true,
							sortingFn: weiSortingFn('price'),
						},
						{
							header: () => (
								<FlexDivCol alignItems="flex-end">
									<TableHeader>
										{t('dashboard.overview.futures-markets-table.daily-volume')}
									</TableHeader>
									<TableHeader>
										{t('dashboard.overview.futures-markets-table.funding-rate')}
									</TableHeader>
								</FlexDivCol>
							),
							accessorKey: 'dailyVolume',
							cell: (cellProps) => {
								return (
									<FlexDivRight>
										<Currency.Price
											price={cellProps.row.original.volume ?? 0}
											// formatOptions={{ truncateOver: 1e3 }}
											fontSize={14}
											mono
										/>
										<ChangePercent
											showArrow={false}
											value={cellProps.row.original.fundingRate}
											decimals={6}
											className="change-pct"
											fontSize={14}
											mono
										/>
									</FlexDivRight>
								)
							},
							size: 120,
							enableSorting: true,
							sortingFn: weiSortingFn('volume'),
						},
					]}
					noResultsMessage={
						data?.length === 0 &&
						(activeFilter === 'forex' ? (
							<TableNoResults>
								{t('dashboard.overview.futures-markets-table.no-forex-pairs')}
							</TableNoResults>
						) : (
							<TableNoResults>
								{t('dashboard.overview.futures-markets-table.no-results')}
							</TableNoResults>
						))
					}
				/>
			</DesktopSmallOnlyView>
		</>
	)
}

const StyledCurrencyIcon = styled(Currency.Icon)`
	width: 30px;
	height: 30px;
`

const OpenInterestContainer = styled.div`
	display: flex;
	flex-direction: column;
	text-align: flex-start;
	gap: 4px;
`

const HeroContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 28px;
	padding: 0 20px 16px;

	@media (max-width: 768px) {
		gap: 15px;
		padding: 4px 20px 8px;
	}
`

const Divider = styled.div`
	width: 1px;
	height: 100px;
	background-color: ${(props) => props.theme.colors.selectedTheme.borderColor};
	margin-left: 50px;

	@media (max-width: 768px) {
		height: 65px;
		margin-left: 20px;
	}
`

const TableContainer = styled.div`
	margin: 16px auto;

	.paused {
		color: ${(props) => props.theme.colors.selectedTheme.gray};
	}
`

const StyledTable = styled(Table)`
	margin-bottom: 20px;
	${media.lessThan('xxl')`
    border-radius: 0;
    border-left: none;
    border-right: none;
  `}
	${TableCellHead} {
		text-align: flex-start;
		justify-content: flex-start;
	}
	${TableCell} {
		box-sizing: border-box;
		text-align: flex-start;
		justify-content: flex-start;
	}
	${BaseTableBodyRow} {
		padding: 4px 0;
	}
` as typeof Table

const MarketContainer = styled.div`
	display: flex;
	align-items: center;
	font-size: 14px;
	line-height: 18px;
	height: 48px;
	min-height: 48px;
	overflow: hidden;
	width: 200px;
`

const MarketContent = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 10px;

	@media (max-width: 768px) {
		margin-left: 2px;
	}
`

const StyledText = styled.span<{ mobile?: boolean }>`
	color: ${(props) => props.theme.colors.selectedTheme.newTheme.text.primaryWhite};
	font-family: ${(props) => props.theme.fonts.regular};
	font-size: ${(props) => (props.mobile ? `14px` : `13px`)};
`

const StyledValue = styled.span<{ mobile?: boolean }>`
	color: ${(props) => props.theme.colors.selectedTheme.gray};
	font-family: ${(props) => props.theme.fonts.regular};
	font-size: ${(props) => (props.mobile ? `14px` : `12px`)};
`

const StyledMobileTable = styled(Table)`
	margin-top: 4px;
	${TableCellHead} {
		height: 52px;
		min-height: 52px;
		font-size: 12px;
		&:first-child {
			padding-left: 24px !important;
		}
		&:last-child {
			padding-right: 6px !important;
		}
	}
	${TableCell} {
		&:first-child {
			padding-left: 16px;
			justify-content: flex-start;
		}
		&:last-child {
			padding-right: 16px;
			justify-content: flex-end;
		}
		justify-content: flex-end;
	}
	${BaseTableBodyRow} {
		padding: 6px auto;
	}
` as typeof Table

const FlexDivRight = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;

	p {
		justify-content: flex-end;
		text-align: right;
	}

	span {
		justify-content: flex-end;
		text-align: right;
	}
`

const ButtonMobileDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	position: relative;
	width: 100%;
	margin: 24px 0px;
	padding: 0px 16px;
`

const TabButtonMobileDiv = styled.div`
	display: flex;
	margin-right: auto;
	justify-content: flex-end;
	gap: 10px;
	border-right: ${(props) => props.theme.colors.selectedTheme.border};
	padding-right: 10px;
	margin-right: 10px;
	width: 100%;
`

const ButtonFilter = styled.button<{ active?: boolean; currentTheme?: string }>`
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: ${(props) => props.theme.fonts.regular};
	font-weight: 500;
	font-feature-settings: 'zero' 0;
	font-size: 16px;
	line-height: 20px;
	padding: 0px 12px;
	cursor: pointer;
	border: none;
	user-select: none;
	white-space: nowrap;
	width: auto;
	height: 36px;
	min-height: 36px;
	border-radius: 24px;
	background-color: ${(props) =>
		props.active
			? props.theme.colors.selectedTheme.table.fill
			: props.theme.colors.selectedTheme.button.fillHover};
	color: ${(props) =>
		props.active
			? props.theme.colors.selectedTheme.button.text.lightWhite
			: props.theme.colors.selectedTheme.text.value};

	&:hover {
		filter: ${(props) =>
			props.active
				? 'brightness(1)'
				: props.currentTheme === 'dark'
				? 'brightness(1.1)'
				: 'brightness(0.9)'};
	}
`

const SearchIconStyle = styled.div`
	margin-top: 0px;
	cursor: pointer;
	height: 30px;
	width: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	&:hover {
		background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
		path {
			fill: ${(props) => props.theme.colors.selectedTheme.button.text.primary};
		}
	}
`

const SearchBarContainer = styled.div<{ searchOpen: boolean }>`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	position: relative;
	max-width: none;
	transition: width 0.45s cubic-bezier(0.16, 1, 0.3, 1);
	width: ${(props) => (props.searchOpen ? '100%' : '0')};
	opacity: ${(props) => (props.searchOpen ? '1' : '0')};
`

export default FuturesMarketsTable

import { memo, ChangeEvent, FC, useCallback, useRef } from 'react'
import styled, { css } from 'styled-components'

import CrossIcon from 'assets/svg/app/close.svg'
import Input from 'components/Input/Input'
import media from 'styles/media'

type SearchProps = {
	value: string | undefined
	disabled?: boolean
	border?: boolean
	placeHolder?: string
	autoFocus?: boolean
	onChange: (text: string) => void
	onClear?: () => void
	mobile?: boolean
}

const SearchBarNewMarket: FC<SearchProps> = memo(
	({ value, disabled, border = true, placeHolder, autoFocus, onChange, onClear, mobile }) => {
		const inputRef = useRef<HTMLInputElement>(null!)

		const handleOnChange = useCallback(
			(event: ChangeEvent<HTMLInputElement>) => {
				onChange(event.target.value)
			},
			[onChange]
		)

		const onKeyClear = useCallback(
			(event: React.KeyboardEvent<HTMLInputElement>) => {
				if (event.key === 'Escape') {
					onClear?.()
				}
			},
			[onClear]
		)

		return (
			<SearchBar border={border} mobile={mobile}>
				<IconContainer
					onClick={() => {
						onClear?.()
						inputRef.current?.focus()
					}}
				>
					<StyledCrossIcon mobile={mobile} />
				</IconContainer>
				<SearchInput
					autoFocus={autoFocus}
					border={border}
					value={value}
					onChange={handleOnChange}
					placeholder={placeHolder}
					disabled={disabled}
					onKeyDown={onKeyClear}
					ref={inputRef}
					mobile={mobile}
				/>
			</SearchBar>
		)
	}
)

const IconContainer = styled.div`
	position: absolute;
	left: 2;
	z-index: 1;
	border-radius: 16px;
	padding-top: 3px;
	cursor: pointer;
`

const StyledCrossIcon = styled(CrossIcon)<{ mobile?: boolean }>`
	width: ${(props) => (props.mobile ? 36 : 32)}px;
	height: ${(props) => (props.mobile ? 36 : 32)}px;
	border-radius: 50%;
	background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
	padding: 8px;

	&:hover {
		path {
			stroke: ${(props) => props.theme.colors.selectedTheme.button.text.primary};
		}
	}
`

const SearchInput = styled(Input)<{ border: boolean; mobile?: boolean }>`
	position: relative;
	height: 40px;
	min-height: 40px;
	width: 100%;
	border: 0;
	border-radius: 50px;
	padding: 10px 0px 10px 40px;
	font-size: ${(props) => (props.mobile ? 16 : 14)}px;
	line-height: ${(props) => (props.mobile ? 18 : 20)}px;
	background: ${(props) => props.theme.colors.selectedTheme.table.fill};

	${(props) =>
		props.mobile &&
		css`
			font-size: 16px;
			line-height: 20px;
		`}

	::placeholder {
		color: ${(props) => props.theme.colors.selectedTheme.gray};
	}
`

const SearchBar = styled.div<{ border: boolean; mobile?: boolean }>`
	justify-content: start;
	margin-left: 0;
	padding-left: 0;
	opacity: 1;
	width: var(${CrossIcon});
	border-radius: 16px;
	font-size: 14px;
	line-height: 18px;
	height: ${(props) => (props.mobile ? `40px` : `32px`)};
	min-height: ${(props) => (props.mobile ? `40px` : `32px`)};
	font-family: ${(props) => props.theme.fonts.mono};
	font-weight: 400;
	display: flex;
	flex: 1 1;
	overflow: hidden;
	align-items: center;
	background: ${(props) =>
		props.border
			? props.theme.colors.selectedTheme.input.background
			: props.theme.colors.selectedTheme.newTheme.containers.primary.background};
`

export default SearchBarNewMarket
